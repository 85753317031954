import React, { useEffect, useState } from 'react'
import './home.css'
import List from '@mui/material/List';
import { Dashboard, ExitToApp, Menu, PlayCircle, Tune, WorkspacePremium } from '@mui/icons-material'
import { AppBar, Button, Card, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/system'
import dishaLogo from '../../assets/images/Dishaflex_logo.png'
import sashaLogo from '../../assets/images/sasha.png'

const courseRow = (courses, key, colors, navigate) => {
  return (<div className='row' key={key}>
    {courses.map((course, i) => (<div className='col-sm-4 mb-4'><Card className='d-flex p-3 flex-column h-100' key={i}>
      <h4>{course.name}</h4>
      <small style={{ flex: 1 }}>{course.description}</small>
      <div className='p-2'></div>
      <Divider />
      <div className='p-2'></div>
      <div><strong>Level</strong>: {course.level}</div>
      <div><strong>Duration</strong>: {course.duration} mins</div>
      <div><strong>Certificate</strong>: {course.certificate}</div>
      <div><strong>Credit Points</strong>: {course.credits}</div>
      <div className='p-2'></div>
      <div><Button variant='contained' onClick={() => navigate(`/course/${course.id}`)}>Start Course</Button></div>
    </Card></div>))}
  </div>)
}

const Home = () => {
  const colors = ['FF731D', 'B73E3E', '002E94', '256D85', '5C2E7E', 'F96666', '31E1F7', '0F3460']
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(true);

  useEffect(() => {
    const courses = [
      {
        id: 1,
        name: 'PoSH 101 – Explorer',
        description: 'The objective of this  program is to create awareness on sexual harassment, provide you with a concrete understanding about POSH.',
        level: 1,
        duration: 60,
        credits: 20
      },
      {
        id: 2,
        name: 'PoSH 101 – Inquirer',
        description: 'This  program is curated specifically for managers and supervisors and seeks to address the specific action that is expected from a senior member of the team to ensure a respectful and safe work environment.',
        level: 1,
        duration: 60,
        credits: 20
      },
      {
        id: 3,
        name: 'PoSH 101 – Enabler',
        description: 'This program is designed for the Senior or Executive Leadership team',
        level: 1,
        duration: 60,
        credits: 20
      },
      {
        id: 4,
        name: 'DEI Barrier – Microaggression at Workplace',
        description: 'Described as “Death by Thousand Cuts” – This module is designed to help ensure that your workplace is a safe, harmonious, and inclusive environment for all employees.',
        level: 1,
        duration: 60,
        credits: 20
      },
      {
        id: 5,
        name: 'DEI Barrier – Unconscious Bias',
        description: 'Our willingness to examine our own unconscious bias is an important step in understanding the roots of stereotypes and prejudice in our society.',
        level: 1,
        duration: 60,
        credits: 20
      },
      {
        id: 6,
        name: 'Women Lead – The sky is the limit',
        description: 'Women Lead program, a 360-degree program for emerging Woman Leaders has been curated after careful understanding of the many barriers that prevent her to rise and shine and most importantly sustain her success.',
        level: 1,
        duration: 60,
        credits: 20
      }
    ]
    const rows = [];
    for (let i = 0; i < courses.length; i += 3) {
      rows.push([]);
      for (let j = 0; j < 3 && i + j < courses.length; j++) {
        let index = Math.floor(i / 3);
        rows[index].push(courses[i + j])
      }
    }
    setRows(rows);
  }, []);

  const toggleSidebar = () => {
    let newSidebar = !sidebar;
    setSidebar(newSidebar);
  }

  return (
    <div className='d-flex' style={{ width: '100vw', height: '100vh' }}>
      <div className='sidebar bg-dark' style={{ width: sidebar ? '250px' : 0 }}>
        <div style={{ display: sidebar ? 'block' : 'none' }}>
          <div className='p-2'></div>
          <div className='text-center d-flex align-items-center'><img alt='' src={dishaLogo} style={{ width: 60 }} className='me-2' /><h3 className='text-white'>LinearLMS</h3></div>
          <div className='p-2'></div>
          <div className='text-center'><img alt='' src='https://picsum.photos/120/120' className='rounded-circle' /></div>
          <div className='p-2'></div>
          <div className='text-center'><h4 className='text-white'>Chandrakant Singh</h4></div>
          <div className='text-center text-white'><small>Consultant - Architect</small></div>
          <div className='p-2'></div>
          <div className='text-center d-flex flex-column'>
            <div className='text-white text-start'>
              <List>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <Dashboard color='info' />
                    </ListItemIcon>
                    <ListItemText primary='Dashboard' selected />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <PlayCircle color='info' />
                    </ListItemIcon>
                    <ListItemText primary='Courses' selected />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <WorkspacePremium color='info' />
                    </ListItemIcon>
                    <ListItemText primary='Certificates' selected />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <Tune color='info' />
                    </ListItemIcon>
                    <ListItemText primary='Preferences' selected />
                  </ListItemButton>
                </ListItem>
              </List>
            </div>
            <div className='p-4'></div>
            <div className='text-white'>Powered by <img alt='' src={sashaLogo} style={{ width: 40 }} /></div>
          </div>
        </div>
      </div>
      <div className='main-content d-flex flex-column' style={{ flex: 1, overflow: 'auto' }}>
        <Box>
          <AppBar position='static'>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => toggleSidebar()}
              ><Menu /></IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              </Typography>
              <IconButton onClick={() => navigate('/login')} color='inherit' ><ExitToApp /></IconButton>
            </Toolbar>
          </AppBar>
        </Box>
        <div className='content-area p-4'>
          <div className='d-flex'>
            <div>
              <Card className='p-4'>
                <h1 className='text-center'>20</h1>
                <Divider />
                <div className='p-1'></div>
                <p>Credit Points Earned</p>
              </Card>
            </div>
            <div style={{ flexBasis: '1', flexGrow: '100' }} className='text-end'>
              <Button variant='contained'>Download Certificate</Button>
            </div>
          </div>
          <div className='p-3'></div>
          <div>
            <h3>Available courses</h3>
            <div className='p-2'></div>
            {rows.map((row, i) => courseRow(row, i, colors, navigate))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home