import { ExitToApp, Menu, Settings } from '@mui/icons-material';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import dishaLogo from '../../assets/images/Dishaflex_logo.png'
import sashaLogo from '../../assets/images/sasha.png'
import AdminNav from '../../components/admin-nav/AdminNav';

const SettingsPage = () => {
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(true);

  useEffect(() => {
  }, []);

  const toggleSidebar = () => {
    let newSidebar = !sidebar;
    setSidebar(newSidebar);
  }

  return (
    <div className='d-flex' style={{ width: '100vw', height: '100vh' }}>
      <div className='sidebar bg-dark' style={{ width: sidebar ? '250px' : 0 }}>
        <div style={{ display: sidebar ? 'block' : 'none' }}>
          <div className='p-2'></div>
          <div className='text-center d-flex align-items-center'><img alt='' src={dishaLogo} style={{ width: 60 }} className='me-2' /><h3 className='text-white'>LinearLMS</h3></div>
          <div className='p-2'></div>
          <div className='text-center'><img alt='' src='https://picsum.photos/120/120' className='rounded-circle' /></div>
          <div className='p-2'></div>
          <div className='text-center'><h4 className='text-white'>Admin Console</h4></div>
          <div className='text-center text-white'><small>Hi Chandrakant!</small></div>
          <div className='p-2'></div>
          <div className='text-center d-flex flex-column'>
            <div className='text-white text-start'>
              <AdminNav selected='courses' />
            </div>
            <div className='p-4'></div>
            <div className='text-white'>Powered by <img alt='' src={sashaLogo} style={{ width: 40 }} /></div>
          </div>
        </div>
      </div>
      <div className='main-content d-flex flex-column' style={{ flex: 1, overflow: 'auto' }}>
        <Box>
          <AppBar position='static'>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => toggleSidebar()}
              ><Menu /></IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              </Typography>
              <IconButton onClick={() => navigate('/login')} color='inherit' ><ExitToApp /></IconButton>
            </Toolbar>
          </AppBar>
        </Box>
        <div className='content-area p-4 d-flex flex-column' style={{ flex: 1 }}>
          <h3>Settings</h3>

        </div>
      </div>
    </div>
  )
}

export default SettingsPage