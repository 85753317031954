import { Button, Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './section.css'

const Section = () => {
  const [course, setCourse] = useState({});
  const [vid, setVideo] = useState({});
  const [showQuestions, setShowQuestions] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const course = {
      id: 1,
      name: 'Lorem ipsum dolor sit amet',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tortor ante, auctor in erat eu, faucibus vehicula urna.',
      level: 1,
      code: 'LIPSUM-01',
      duration: 60,
      certificate: 'Yes',
      credits: 20,
      introduction: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tortor ante, auctor in erat eu, faucibus vehicula urna. Nulla eget massa ligula. Donec lacus nulla, sagittis non bibendum sed, pulvinar in leo. Aliquam ipsum sem, tempor eget eleifend quis, congue et ipsum. Sed nec sagittis arcu. Etiam eros est, facilisis vitae venenatis non, ultricies quis eros. Curabitur sagittis velit at tellus semper, id dictum enim tempor. In hac habitasse platea dictumst. Praesent eget sodales purus, vel rutrum lacus. Praesent placerat quam a lacus fringilla volutpat. Fusce vel tellus leo. Ut egestas, eros non mollis sollicitudin, augue neque sollicitudin odio, ac scelerisque est nisl id diam. Proin ac lectus id dui condimentum sagittis et ut arcu. In a suscipit quam.',
      videos: [
        {
          id: 1,
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          scenario: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lacinia placerat risus a vehicula. Nunc lobortis volutpat mi, non consequat orci convallis quis. Nulla et feugiat mauris. Sed ut libero ut turpis ultrices interdum. In mattis elementum congue. Ut tristique feugiat turpis, sed congue orci dapibus in. Mauris in semper neque, posuere gravida tortor. Ut consectetur turpis risus, facilisis sagittis odio rhoncus et. Morbi at tortor vitae ante ultricies tempor. Duis sit amet massa lacinia, facilisis est vulputate, vestibulum nunc. Sed in viverra nisl.',
          videoUrl: '/videos/Chapter1.mp4',
          questions: [
            {
              text: 'Do you think the video was useful in understanding the scenario, actions to be taken when it plays out in real life and consequences of these actions?',
              reactionType: 'Yes-No-DontKnow'
            },
            {
              text: 'Have you ever encountered such a scenario in your life?',
              reactionType: 'Yes-No-WontSay'
            }
          ]
        },
        {
          id: 2,
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          scenario: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lacinia placerat risus a vehicula. Nunc lobortis volutpat mi, non consequat orci convallis quis. Nulla et feugiat mauris. Sed ut libero ut turpis ultrices interdum. In mattis elementum congue. Ut tristique feugiat turpis, sed congue orci dapibus in. Mauris in semper neque, posuere gravida tortor. Ut consectetur turpis risus, facilisis sagittis odio rhoncus et. Morbi at tortor vitae ante ultricies tempor. Duis sit amet massa lacinia, facilisis est vulputate, vestibulum nunc. Sed in viverra nisl.',
          videoUrl: '/videos/Chapter1.mp4',
          questions: [
            {
              text: 'Do you think the video was useful in understanding the scenario, actions to be taken when it plays out in real life and consequences of these actions?',
              reactionType: 'Yes-No-DontKnow'
            },
            {
              text: 'Have you ever encountered such a scenario in your life?',
              reactionType: 'Yes-No-WontSay'
            }
          ]
        },
        {
          id: 3,
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          scenario: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lacinia placerat risus a vehicula. Nunc lobortis volutpat mi, non consequat orci convallis quis. Nulla et feugiat mauris. Sed ut libero ut turpis ultrices interdum. In mattis elementum congue. Ut tristique feugiat turpis, sed congue orci dapibus in. Mauris in semper neque, posuere gravida tortor. Ut consectetur turpis risus, facilisis sagittis odio rhoncus et. Morbi at tortor vitae ante ultricies tempor. Duis sit amet massa lacinia, facilisis est vulputate, vestibulum nunc. Sed in viverra nisl.',
          videoUrl: '/videos/Chapter1.mp4',
          questions: [
            {
              text: 'Do you think the video was useful in understanding the scenario, actions to be taken when it plays out in real life and consequences of these actions?',
              reactionType: 'Yes-No-DontKnow'
            },
            {
              text: 'Have you ever encountered such a scenario in your life?',
              reactionType: 'Yes-No-WontSay'
            }
          ]
        },
        {
          id: 4,
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          scenario: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lacinia placerat risus a vehicula. Nunc lobortis volutpat mi, non consequat orci convallis quis. Nulla et feugiat mauris. Sed ut libero ut turpis ultrices interdum. In mattis elementum congue. Ut tristique feugiat turpis, sed congue orci dapibus in. Mauris in semper neque, posuere gravida tortor. Ut consectetur turpis risus, facilisis sagittis odio rhoncus et. Morbi at tortor vitae ante ultricies tempor. Duis sit amet massa lacinia, facilisis est vulputate, vestibulum nunc. Sed in viverra nisl.',
          videoUrl: '/videos/Chapter1.mp4',
          questions: [
            {
              text: 'Do you think the video was useful in understanding the scenario, actions to be taken when it plays out in real life and consequences of these actions?',
              reactionType: 'Yes-No-DontKnow'
            },
            {
              text: 'Have you ever encountered such a scenario in your life?',
              reactionType: 'Yes-No-WontSay'
            }
          ]
        }
      ],
      questions: [
        {
          text: 'Do you think the video was useful in understanding the scenario, actions to be taken when it plays out in real life and consequences of these actions?',
          reactionType: 'Yes-No-DontKnow'
        },
        {
          text: 'Have you ever encountered such a scenario in your life?',
          reactionType: 'Yes-No-WontSay'
        }
      ]
    };
    setCourse(course);
    const vid = {
      id: 1,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      scenario: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lacinia placerat risus a vehicula. Nunc lobortis volutpat mi, non consequat orci convallis quis. Nulla et feugiat mauris. Sed ut libero ut turpis ultrices interdum. In mattis elementum congue. Ut tristique feugiat turpis, sed congue orci dapibus in. Mauris in semper neque, posuere gravida tortor. Ut consectetur turpis risus, facilisis sagittis odio rhoncus et. Morbi at tortor vitae ante ultricies tempor. Duis sit amet massa lacinia, facilisis est vulputate, vestibulum nunc. Sed in viverra nisl.',
      videoUrl: '/videos/Chapter1.mp4',
      questions: [
        {
          text: 'Do you think the video was useful in understanding the scenario, actions to be taken when it plays out in real life and consequences of these actions?',
          reactionType: 'Yes-No-DontKnow'
        },
        {
          text: 'Have you ever encountered such a scenario in your life?',
          reactionType: 'Yes-No-WontSay'
        }
      ]
    };
    setVideo(vid);
  }, [])

  return (
    <div className='d-flex' style={{ width: '100%', minHeight: '100vh' }}>
      <div style={{ flexBasis: 1, flexGrow: 20 }} className='bg-dark p-4'>
        <div className='text-center'>
          <img src='https://picsum.photos/150/150' className='rounded-circle' />
          <h3 className='text-white'>Welcome User!</h3>
          <div className='p-3'></div>
          <Divider />
          <div className='p-3'></div>
        </div>
        <div className='text-white'>
          <strong>Course Name</strong>
          <div><small>{course.name}</small></div>
          <div className='p-1'></div>
          <strong>Code</strong>
          <div><small>{course.code}</small></div>
          <div className='p-1'></div>
          <strong>Duration</strong>
          <div><small>{course.duration} mins</small></div>
          <div className='p-1'></div>
          <strong>Certificate</strong>
          <div><small>{course.certificate}</small></div>
          <div className='p-1'></div>
          <strong>Credit Points</strong>
          <div><small>{course.credits}</small></div>
          <div className='p-1'></div>
          <strong>Course Level</strong>
          <div><small>{course.level}</small></div>
          <div className='p-1'></div>
        </div>
        <div className='p-2'></div>
        <div className='text-white'>
          <strong>About the course</strong>
          <div style={{ textAlign: 'justify' }}><small>{vid.description}</small></div>
        </div>
      </div>
      <div style={{ flexBasis: 1, flexGrow: 80 }} className='p-4'>
        <h1>{vid.description}</h1>
        <div className='p-2'></div>
        <p>{vid.scenario}</p>
        <div className='p-2'></div>
        <div className='text-center'>
          {vid.videoUrl && !showQuestions ? <video src={vid.videoUrl} preload='auto' autoPlay onEnded={() => setShowQuestions(true)} controls={true} controlsList='nodownload' width={800} onContextMenu={(e) => e.preventDefault()}></video> : <div></div>}
          {showQuestions ? <div>
            {vid.questions.map(q => (
              <div className='text-start'>
                <h5><strong>{q.text}</strong></h5>
                <RadioGroup>
                  <FormControlLabel value={'yes'} control={<Radio />} label='Yes'/>
                  <FormControlLabel value={'no'} control={<Radio />} label='No'/>
                  <FormControlLabel value={'maybe'} control={<Radio />} label='Maybe'/>
                </RadioGroup>
                <div className='p-2'></div>
              </div>
            ))}
          </div> : <div></div>}
        </div>
        <div className='p-2'></div>
        <div className='text-center'>
          <Button variant='contained' color='error' onClick={() => navigate('/course/1/section/1')}>Previous</Button>
          <span className='p-1'></span>
          <Button variant='contained' color='error' onClick={() => navigate('/course/1/section/1')} disabled={!showQuestions}>Next</Button>
        </div>
      </div>
    </div>
  )
}

export default Section