import { Dashboard, Group, PlayCircle, Settings } from '@mui/icons-material'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const AdminNav = (props) => {
  const navigate = useNavigate();

  const showDashboard = (url) => {
    navigate('/dashboard');
  }

  const showCourses = (url) => {
    navigate('/courses');
  }

  const showUsers = (url) => {
    navigate('/users');
  }

  const showSettings = (url) => {
    navigate('/settings');
  }

  return (
    <div>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => showDashboard()}>
            <ListItemIcon>
              <Dashboard color='info' />
            </ListItemIcon>
            <ListItemText primary='Dashboard' />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => showCourses()}>
            <ListItemIcon>
              <PlayCircle color='info' />
            </ListItemIcon>
            <ListItemText primary='Courses' />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => showUsers()}>
            <ListItemIcon>
              <Group color='info' />
            </ListItemIcon>
            <ListItemText primary='Users' selected />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => showSettings()}>
            <ListItemIcon>
              <Settings color='info' />
            </ListItemIcon>
            <ListItemText primary='Settings' selected />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  )
}

export default AdminNav