import { Add, Delete, Edit, ExitToApp, Menu } from '@mui/icons-material';
import { AppBar, Box, Fab, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import dishaLogo from '../../assets/images/Dishaflex_logo.png'
import sashaLogo from '../../assets/images/sasha.png'
import AdminNav from '../../components/admin-nav/AdminNav';

const Users = () => {
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    let data = [
      {
        id: '62389',
        name: 'Dr Alban',
        email: 'dralban@llms.com',
        phone: '8933403458'
      },
      {
        id: '67345',
        name: 'Katy Perry',
        email: 'katy@llms.com',
        phone: '98647723645'
      },
      {
        id: '65524',
        name: 'Charlie Puth',
        email: 'charlie@llms.com',
        phone: '8790098798'
      },
      {
        id: '69345',
        name: 'Taylor Swift',
        email: 'taylorswift@llms.com',
        phone: '4782728836'
      },
      {
        id: '67384',
        name: 'Ariana Grande',
        email: 'ariana@llms.com',
        phone: '9378847746'
      },
      {
        id: '68667',
        name: 'Dua Lipa',
        email: 'dualipa@llms.com',
        phone: '8499578784'
      },
      {
        id: '65657',
        name: 'Ed Sheeran',
        email: 'edsheeran@llms.com',
        phone: '8599688478'
      },
      {
        id: '65884',
        name: 'Lady Gaga',
        email: 'ladygaga@llms.com',
        phone: '8549688478'
      },
      {
        id: '65884',
        name: 'Shawn Mendes',
        email: 'shawnmendes@llms.com',
        phone: '8699484993'
      },
      {
        id: '65884',
        name: 'Rihanna',
        email: 'rihanna@llms.com',
        phone: '9588937723'
      }
    ]
    setData(data);
  }, []);

  const toggleSidebar = () => {
    let newSidebar = !sidebar;
    setSidebar(newSidebar);
  }

  return (
    <div className='d-flex' style={{ width: '100vw', height: '100vh' }}>
      <div className='sidebar bg-dark' style={{ width: sidebar ? '250px' : 0 }}>
        <div style={{ display: sidebar ? 'block' : 'none' }}>
          <div className='p-2'></div>
          <div className='text-center d-flex align-items-center'><img alt='' src={dishaLogo} style={{ width: 60 }} className='me-2' /><h3 className='text-white'>LinearLMS</h3></div>
          <div className='p-2'></div>
          <div className='text-center'><img alt='' src='https://picsum.photos/120/120' className='rounded-circle' /></div>
          <div className='p-2'></div>
          <div className='text-center'><h4 className='text-white'>Admin Console</h4></div>
          <div className='text-center text-white'><small>Hi Chandrakant!</small></div>
          <div className='p-2'></div>
          <div className='text-center d-flex flex-column'>
            <div className='text-white text-start'>
              <AdminNav selected='courses' />
            </div>
            <div className='p-4'></div>
            <div className='text-white'>Powered by <img alt='' src={sashaLogo} style={{ width: 40 }} /></div>
          </div>
        </div>
      </div>
      <div className='main-content d-flex flex-column' style={{ flex: 1, overflow: 'auto' }}>
        <Box>
          <AppBar position='static'>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => toggleSidebar()}
              ><Menu /></IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              </Typography>
              <IconButton onClick={() => navigate('/login')} color='inherit' ><ExitToApp /></IconButton>
            </Toolbar>
          </AppBar>
        </Box>
        <div className='content-area p-4 d-flex flex-column' style={{ flex: 1 }}>
          <div className='d-flex align-items-center justify-content-between'>
            <h3>Users</h3>
            <div>
              <Fab color='primary' size='small'>
                <Add />
              </Fab>
            </div>
          </div>
          <div className='p-2'></div>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Employee ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell style={{ width: 120 }}>
                      <IconButton>
                        <Edit color='primary' />
                      </IconButton>
                      <IconButton>
                        <Delete color='error' />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className='p-2'></div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={6}
            rowsPerPage={10}
            page={0}
            onPageChange={() => { }}
            onRowsPerPageChange={() => { }}
          />
        </div>
      </div>
    </div>
  )
}

export default Users