import { Dashboard, ExitToApp, Group, Menu, PlayCircle, Settings } from '@mui/icons-material';
import { AppBar, Box, Button, Card, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import dishaLogo from '../../assets/images/Dishaflex_logo.png'
import sashaLogo from '../../assets/images/sasha.png'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import AdminNav from '../../components/admin-nav/AdminNav';

const NewAdminHome = () => {
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(true);
  const [coursePerformanceData, setCoursePerformanceData] = useState([]);
  const [completionTimeData, setCompletionTimeData] = useState([]);

  useEffect(() => {
    let data = [
      {
        name: 'PoSH 101 – Explorer',
        'Completed': 550,
        'In Progress': 100,
        'Not Started': 50
      },
      {
        name: 'PoSH 101 – Inquirer',
        'Completed': 300,
        'In Progress': 300,
        'Not Started': 100
      },
      {
        name: 'PoSH 101 – Enabler',
        'Completed': 450,
        'In Progress': 250,
        'Not Started': 0
      },
      {
        name: 'DEI Barrier – Microaggression at Workplace',
        'Completed': 100,
        'In Progress': 150,
        'Not Started': 450
      },
      {
        name: 'DEI Barrier – Unconscious Bias',
        'Completed': 100,
        'In Progress': 400,
        'Not Started': 200
      },
      {
        name: 'Women Lead – The sky is the limit',
        'Completed': 300,
        'In Progress': 200,
        'Not Started': 200
      }
    ];
    let data1 = [
      {
        name: 'PoSH 101 – Explorer',
        'Time': 35
      },
      {
        name: 'PoSH 101 – Inquirer',
        'Time': 30
      },
      {
        name: 'PoSH 101 – Enabler',
        'Time': 45
      },
      {
        name: 'DEI Barrier – Microaggression at Workplace',
        'Time': 37
      },
      {
        name: 'DEI Barrier – Unconscious Bias',
        'Time': 42
      },
      {
        name: 'Women Lead – The sky is the limit',
        'Time': 40
      }
    ]
    setCoursePerformanceData(data);
    setCompletionTimeData(data1);
  }, []);

  const toggleSidebar = () => {
    let newSidebar = !sidebar;
    setSidebar(newSidebar);
  }

  return (
    <div className='d-flex' style={{ width: '100vw', height: '100vh' }}>
      <div className='sidebar bg-dark' style={{ width: sidebar ? '250px' : 0 }}>
        <div style={{ display: sidebar ? 'block' : 'none' }}>
          <div className='p-2'></div>
          <div className='text-center d-flex align-items-center'><img alt='' src={dishaLogo} style={{ width: 60 }} className='me-2' /><h3 className='text-white'>LinearLMS</h3></div>
          <div className='p-2'></div>
          <div className='text-center'><img alt='' src='https://picsum.photos/120/120' className='rounded-circle' /></div>
          <div className='p-2'></div>
          <div className='text-center'><h4 className='text-white'>Admin Console</h4></div>
          <div className='text-center text-white'><small>Hi Chandrakant!</small></div>
          <div className='p-2'></div>
          <div className='text-center d-flex flex-column'>
            <div className='text-white text-start'>
              <AdminNav selected='dashboard' />
            </div>
            <div className='p-4'></div>
            <div className='text-white'>Powered by <img alt='' src={sashaLogo} style={{ width: 40 }} /></div>
          </div>
        </div>
      </div>
      <div className='main-content d-flex flex-column' style={{ flex: 1, overflow: 'auto' }}>
        <Box>
          <AppBar position='static'>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => toggleSidebar()}
              ><Menu /></IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              </Typography>
              <IconButton onClick={() => navigate('/login')} color='inherit' ><ExitToApp /></IconButton>
            </Toolbar>
          </AppBar>
        </Box>
        <div className='content-area p-4 d-flex flex-column' style={{ flex: 1 }}>
          <h3>Admin Dashboard</h3>
          <div className='p-2'></div>
          <div className='row'>
            <div className='col-sm-3'>
              <Card className='shadow p-3 mb-3'>
                <h3 className='stylish'>6</h3>
                <div className='p-2'></div>
                <Divider />
                <div className='p-2'></div>
                <div className='hstack'>
                  <h6>Total Courses</h6>
                  <Button variant='contained' className='ms-auto'>View All</Button>
                </div>
              </Card>
            </div>
            <div className='col-sm-3'>
              <Card className='shadow p-3 mb-3' style={{ backgroundColor: 'red', color: 'white' }}>
                <h3 className='stylish'>700</h3>
                <div className='p-2'></div>
                <Divider />
                <div className='p-2'></div>
                <div className='hstack'>
                  <h6>Users Onboard</h6>
                  <Button variant='contained' className='ms-auto'>View All</Button>
                </div>
              </Card>
            </div>
            <div className='col-sm-3'>
              <Card className='shadow p-3 mb-3' style={{ backgroundColor: '#ff6600', color: 'white' }}>
                <h3 className='stylish'>350</h3>
                <div className='p-2'></div>
                <Divider />
                <div className='p-2'></div>
                <div className='hstack'>
                  <h6>Certified</h6>
                  <Button variant='contained' className='ms-auto'>View All</Button>
                </div>
              </Card>
            </div>
            <div className='col-sm-3'>
              <Card className='shadow p-3 mb-3' style={{ backgroundColor: 'green', color: 'white' }}>
                <h3 className='stylish'>1200</h3>
                <div className='p-2'></div>
                <Divider />
                <div className='p-2'></div>
                <div className='hstack'>
                  <h6>Credits</h6>
                  <Button variant='contained' className='ms-auto'>View All</Button>
                </div>
              </Card>
            </div>
          </div>
          <div className='p-2'></div>
          <div className='row'>
            <div className='col-sm-6'>
              <h4 className='text-center'>Course Performance</h4>
              <div className='p-2'></div>
              <ResponsiveContainer width="100%" minHeight="350px" maxHeight='350px'>
                <BarChart
                  width={500}
                  height={300}
                  data={coursePerformanceData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Completed" fill="#38E54D" />
                  <Bar dataKey="In Progress" fill="#FF884B" />
                  <Bar dataKey="Not Started" fill="#FF1E1E" />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className='col-sm-6'>
              <h4 className='text-center'>Average Completion Time</h4>
              <div className='p-2'></div>
              <ResponsiveContainer width="100%" minHeight="350px" maxHeight='350px'>
                <BarChart
                  width={500}
                  height={300}
                  data={completionTimeData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Time" fill="#2192FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewAdminHome