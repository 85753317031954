import { AppBar, Box, Button, FormControlLabel, IconButton, Radio, RadioGroup, TextField, Toolbar, Typography } from '@mui/material';
import { Menu } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './course.css'
import certBg from '../../assets/images/cert-bg.jpg'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { ExitToApp, Settings } from '@mui/icons-material';

import dishaLogo from '../../assets/images/Dishaflex_logo.png'
import sashaLogo from '../../assets/images/sasha.png'

const Course = () => {
  const [course, setCourse] = useState({});
  const [currentSection, setCurrentSection] = useState(0);
  const [showVideo, setShowVideo] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [showQuiz, setShowQuiz] = useState(false);
  const [showCertificate, setShowCertificate] = useState(false);
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(true);

  useEffect(() => {
    const course = {
      id: 1,
      name: 'PoSH 101 – Explorer',
      description: 'The objective of this  program is to create awareness on sexual harassment, provide you with a concrete understanding about POSH.',
      level: 1,
      code: 'POSH-101-EXP',
      duration: 60,
      certificate: 'Yes',
      credits: 20,
      introduction: `The objective of this  program is to create awareness on sexual harassment, provide you with a concrete understanding about POSH. This self paced module is spread across five video-based modules that depict the various aspects of the PoSH. With the help of real-life scenarios and cases, the objective of this program is to ensure:
1. Understand of PoSH Act of 2013
2. Recognize & Prevent incidents of Sexual Harassment at workplace
3. Report Incidents appropriately`,
      sections: [
        {
          id: 1,
          description: 'What is sexual harassment?',
          scenario: 'In this section we are going to understand what is considered as sexual harassment and how to identify it when its happening.',
          videoUrl: '/videos/Chapter1.mp4',
          questions: [
            {
              text: 'Reflect upon your learning & observation from the module/video you just saw',
              type: 'OPEN-ENDED'
            },
            {
              text: 'Can you relate to some of the aspects covered in the module/video?',
              type: 'YES-NO-PREFER-NOT'
            },
            {
              text: 'Additional Thoughts/Comments',
              type: 'OPEN-ENDED'
            }
          ]
        },
        {
          id: 2,
          description: 'The process of filing complaint with IC.',
          scenario: 'The Internal commitee is in place to protect and safeguard the rights of an individual in the event of sexual harassment and to met out corrective measures. In this secrion we are going to take a look at the process of raising a complaint with the Internal Commitee.',
          videoUrl: '/videos/Chapter2.mp4',
          questions: [
            {
              text: 'Reflect upon your learning & observation from the module/video you just saw',
              type: 'OPEN-ENDED'
            },
            {
              text: 'Can you relate to some of the aspects covered in the module/video?',
              type: 'YES-NO-PREFER-NOT'
            },
            {
              text: 'Additional Thoughts/Comments',
              type: 'OPEN-ENDED'
            }
          ]
        },
        {
          id: 3,
          description: 'Fear and inhibition while making a complaint.',
          scenario: 'Often incidents go unreported in case the complainant has fear, inhibition or bias in mind. Unfortunately this encourages the respondent to assume its okay to continue doing what they did thus resulting in more harm done to others. In this section we are going to see the various factors that cause individuals to prohibit themselves for raising a complaint and remedies.',
          videoUrl: '/videos/Chapter3.mp4',
          questions: [
            {
              text: 'Reflect upon your learning & observation from the module/video you just saw',
              type: 'OPEN-ENDED'
            },
            {
              text: 'Can you relate to some of the aspects covered in the module/video?',
              type: 'YES-NO-PREFER-NOT'
            },
            {
              text: 'Additional Thoughts/Comments',
              type: 'OPEN-ENDED'
            }
          ]
        },
        {
          id: 4,
          description: 'Social concerns.',
          scenario: 'Often social concerns and sitgma are the reason why individuals fail to complain. People often think that ignoring is the solution which often does more harm than good. Lets take a look at the social setup which causes cases to go unreported and undertand what can be done in such cases.',
          videoUrl: '/videos/Chapter4.mp4',
          questions: [
            {
              text: 'Reflect upon your learning & observation from the module/video you just saw',
              type: 'OPEN-ENDED'
            },
            {
              text: 'Can you relate to some of the aspects covered in the module/video?',
              type: 'YES-NO-PREFER-NOT'
            },
            {
              text: 'Additional Thoughts/Comments',
              type: 'OPEN-ENDED'
            }
          ]
        }
      ],
      quiz: {
        qualifyingScore: 20,
        questions: [
          {
            text: 'Sexual Harassment can occur outside the worksite and still can be considered work related. Incidents that occur at retirement parties and office socials or in trainings are some of the situations where work related harassments occur.',
            type: 'TRUE-FALSE'
          },
          {
            text: '__________________refers to any form of unwanted sexual attention',
            type: 'SINGLE-ANSWER-MCQ',
            options: ['Sexual Assault', 'Sexual Harassment']
          },
          {
            text: 'State whether the following holds true or false in reference to sexual Harassment, “ In your intentions are good your behaviour cannot be considered as sexual harassment',
            type: 'TRUE-FALSE'
          },
          {
            text: 'Sexual harassment will Ultimately go away if ignored',
            type: 'TRUE-FALSE'
          },
          {
            text: 'Friendly flirting is not sexual harassment when flirting is practiced between mutually consenting individual who are in equal power or authority',
            type: 'TRUE-FALSE'
          },
          {
            text: 'Terms of Endearment with co-workers i.e. “honey” “dear” are considered as verbal abuse and charges can be brought up against employee.',
            type: 'TRUE-FALSE'
          },
          {
            text: 'State whether the following statement holds true or false, “incidents of harassment are made public within the organisation to keep employees informed.',
            type: 'TRUE-FALSE'
          },
          {
            text: '“A man who is sexually harassed does not have equal right as women” State true or false',
            type: 'TRUE-FALSE'
          },
          {
            text: 'Does circulation of inappropriate jokes without permission constitute sexual harassment?',
            type: 'TRUE-FALSE'
          },
          {
            text: 'Does display objects of sexual nature (Picture, Posters, screen savers) constitute sexual harassment?',
            type: 'TRUE-FALSE'
          }
        ]
      }
    };
    setCourse(course);
  }, [])

  const onPrevious = () => {
    if (currentSection > 0) {
      const section = currentSection - 1;
      let video = true;
      setCurrentSection(section);
      setShowVideo(video);
    }
  }

  const onNext = () => {
    if (currentSection < course.sections.length - 1) {
      const section = currentSection + 1;
      let video = true;
      setCurrentSection(section);
      setShowVideo(video);
    }
  }

  const toggleSidebar = () => {
    let newSidebar = !sidebar;
    setSidebar(newSidebar);
  }

  const onQuizSubmission = () => {
    const showCertificate = true;
    const quiz = false;
    const video = false;
    const intro = false;
    setShowCertificate(showCertificate);
    setShowQuiz(quiz);
    setShowVideo(video);
    setShowIntro(intro);
  }

  const onStartCourse = () => {
    const introduction = false;
    const currentSection = 0;
    const showVideo = true;
    const showQuiz = false;
    setShowIntro(introduction);
    setCurrentSection(currentSection);
    setShowVideo(showVideo);
    setShowQuiz(showQuiz);
  }

  return (
    <div className='d-flex' style={{ width: '100vw', height: '100vh' }}>
      <div className='sidebar bg-dark' style={{ width: sidebar ? '250px' : 0 }}>
        <div style={{ display: sidebar ? 'block' : 'none' }}>
          <div className='text-center p-3'>
            <div className='text-center d-flex align-items-center'><img alt='Disha Logo' src={dishaLogo} style={{ width: 60 }} className='me-2' /><h3 className='text-white'>LinearLMS</h3></div>
            {/* <img src='https://picsum.photos/150/150' className='rounded-circle' /> */}
            {/* <h3 className='text-white'>Welcome User!</h3> */}
            <div className='p-3'></div>
          </div>
          <h5><strong className='text-danger p-3'>{course.name}</strong></h5>
          <div><small className='p-3 text-warning'><strong>Code: </strong>{course.code}</small></div>
          <div><small className='p-3 text-warning'><strong>Duration: </strong>{course.duration} mins</small></div>
          <div><small className='p-3 text-warning'><strong>Level: </strong>{course.level}</small></div>
          <div><small className='p-3 text-warning'><strong>Certificate: </strong>{course.certificate}</small></div>
          <div><small className='p-3 text-warning'><strong>Credits: </strong>{course.credits}</small></div>
          <div className='text-white p-3'>
            <div style={{ textAlign: 'justify' }}><small>{course.description}</small></div>
          </div>
          <div className='p-4'></div>
          <div className='text-white text-center'>Powered by <img alt='Sasha Logo' src={sashaLogo} style={{ width: 40 }} /></div>
        </div>
      </div>
      <div className='main-content d-flex flex-column' style={{ flex: 1, overflow: 'auto' }}>
        <Box>
          <AppBar position='static'>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => toggleSidebar()}
              ><Menu /></IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>{course.name}</Typography>
              <IconButton onClick={() => navigate('/settings')} color='inherit' ><Settings /></IconButton>
              <IconButton onClick={() => navigate('/login')} color='inherit' ><ExitToApp /></IconButton>
            </Toolbar>
          </AppBar>
        </Box>
        <div className='content-area p-4'>
          {showIntro && !showQuiz && !showVideo && !showCertificate ?
            <div>
              <h1>Introduction</h1>
              <div className='p-2'></div>
              <ReactMarkdown>{course.introduction}</ReactMarkdown>
              <div className='p-2'></div>
              <div className='text-center d-flex justify-content-center gap-2'>
                <Button variant='contained' color='error' onClick={() => onStartCourse()}>Start Course</Button>
                <Button variant='contained' color='primary' onClick={() => navigate('/home')}>Cancel</Button>
              </div>
            </div> : !showIntro && !showQuiz && showVideo && !showCertificate ?
              <div>
                <div className='d-flex align-items-center justify-content-between'><h3>{course.sections[currentSection].description}</h3><Button variant='contained' color='primary' onClick={() => navigate('/home')}>Exit</Button></div>
                <div className='p-2'></div>
                <p>{course.sections[currentSection].scenario}</p>
                <div className='p-2'></div>
                <div className='text-center'>
                  <video style={{maxWidth: '100%'}} src={course.sections[currentSection].videoUrl} preload='auto' autoPlay onEnded={() => setShowVideo(false)} controls={true} controlsList='nodownload' onContextMenu={(e) => e.preventDefault()}></video>
                </div>
                <div className='p-2'></div>
                <div className='text-center'>
                  <Button variant='contained' color='error' onClick={() => onPrevious()} disabled={currentSection === 0}>Previous</Button>
                  <span className='p-1'></span>
                  <Button variant='contained' color='error' onClick={() => onNext()} disabled={showVideo}>Next</Button>
                </div>
              </div> : !showIntro && !showQuiz && !showVideo && !showCertificate ?
                <div>
                  <div className='d-flex align-items-center justify-content-between'><h3>Questions</h3><Button variant='contained' color='primary' onClick={() => navigate('/home')}>Exit</Button></div>
                  <div className='p-2'></div>
                  <div className='text-center'>
                    <div>
                      {course.sections[currentSection].questions.map((q, i) => (
                        <div className='text-start'>
                          <h5><strong>{i + 1}. {q.text}</strong></h5>
                          {q.type === 'YES-NO-PREFER-NOT' ? <RadioGroup>
                            <FormControlLabel value={'yes'} control={<Radio />} label='Yes' />
                            <FormControlLabel value={'no'} control={<Radio />} label='No' />
                            <FormControlLabel value={'preferNotToSay'} control={<Radio />} label='Prefer not to say' />
                          </RadioGroup> : q.type === 'OPEN-ENDED' ? <TextField multiline rows={5} variant='standard' label='Your Response' className='w-100' /> : <div></div>}
                          <div className='p-2'></div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='p-2'></div>
                  <div className='text-center'>
                    <Button variant='contained' color='error' onClick={() => onPrevious()} disabled={currentSection === 0}>Previous</Button>
                    <span className='p-1'></span>
                    {currentSection < course.sections.length - 1 ? <Button variant='contained' color='error' onClick={() => onNext()}>Next</Button> : <Button variant='contained' color='error' onClick={() => setShowQuiz(true)}>Start Quiz</Button>}
                  </div>
                </div> : !showIntro && !showVideo && !showCertificate && showQuiz ?
                  <div>
                    <div className='d-flex align-items-center justify-content-between mb-3'><h3>Quiz</h3><Button variant='contained' color='primary' onClick={() => navigate('/home')}>Exit</Button></div>
                    {course.quiz.questions.map((quizQuestion, i) => <div>
                      <strong>{i + 1}. {quizQuestion.text}</strong>
                      {quizQuestion.type === 'TRUE-FALSE' ? <RadioGroup>
                        <FormControlLabel value={'yes'} control={<Radio />} label='Yes' />
                        <FormControlLabel value={'no'} control={<Radio />} label='No' />
                        <FormControlLabel value={'maybe'} control={<Radio />} label='Maybe' />
                      </RadioGroup> : quizQuestion.type === 'SINGLE-ANSWER-MCQ' ? <RadioGroup>
                        {quizQuestion.options.map((option) => (
                          <FormControlLabel value={option} control={<Radio />} label={option} />
                        ))}
                      </RadioGroup> : <div></div>}
                      <div className='p-3'></div>
                    </div>)}
                    <div className='text-center'><Button variant='contained' color='error' onClick={() => onQuizSubmission()}>Done</Button></div>
                  </div> : !showIntro && !showVideo && !showQuiz && showCertificate ?
                    <div>
                      <div style={{ width: 900, height: 600, overflow: 'auto', maxWidth: '100%', backgroundImage: `url('${certBg}')`, backgroundSize: 'cover' }} className='d-flex align-items-center justify-content-center'>
                        <div className='text-center pb-5'>
                          <h1 className='stylish'>Certificate of completion</h1>
                          <div className='p-3'></div>
                          <p>This certificate is awarded to</p><h3 className='stylish'>Chandrakant Singh</h3>
                          <div className='p-2'></div>
                          <p>For sucessful completion of course on <span className='text-success'>Prevention of Sexual Harassment</span></p>
                        </div>
                      </div>
                      <div className='p-2'></div>
                      <Button variant='contained' color='error' onClick={() => navigate('/home')}>Back to home</Button>
                    </div> : <div></div>}
        </div>
      </div>
    </div>
  )
}

export default Course