import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Login from './pages/login/Login';
import Home from './pages/home/Home';
import Course from './pages/course/Course';
import { createTheme, ThemeProvider } from '@mui/material';
import Section from './pages/section/Section';
import NewAdminHome from './pages/newAdminHome/NewAdminHome';
import Courses from './pages/courses/Courses';
import Users from './pages/users/Users';
import SettingsPage from './pages/settings/Settings';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans',
    ].join(','),
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path='' element={<Login />} />
          <Route path='login' element={<Login />} />
          <Route path='home' element={<Home />} />
          <Route path='dashboard' element={<NewAdminHome />} />
          <Route path='course/:courseId' element={<Course />} />
          <Route path='course/:courseId/section/:sectionId' element={<Section />} />
          <Route path='courses' element={<Courses />} />
          <Route path='users' element={<Users />} />
          <Route path='settings' element={<SettingsPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
