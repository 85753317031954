import { Add, Dashboard, Delete, Edit, ExitToApp, Group, Menu, PlayCircle, Settings } from '@mui/icons-material';
import { AppBar, Box, Button, Fab, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import dishaLogo from '../../assets/images/Dishaflex_logo.png'
import sashaLogo from '../../assets/images/sasha.png'
import AdminNav from '../../components/admin-nav/AdminNav';
import './courses.css'

const Courses = () => {
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    let data = [
      {
        code: 'CR-01',
        name: 'PoSH 101 - Explorer',
        createdAt: new Date(),
        lastModified: new Date()
      },
      {
        code: 'CR-02',
        name: 'PoSH 101 - Inquirer',
        createdAt: new Date(),
        lastModified: new Date()
      },
      {
        code: 'CR-03',
        name: 'PoSH 101 - Enabler',
        createdAt: new Date(),
        lastModified: new Date()
      },
      {
        code: 'CR-04',
        name: 'DEI Barrier - Microaggression at work',
        createdAt: new Date(),
        lastModified: new Date()
      },
      {
        code: 'CR-05',
        name: 'DEI Barrier - Unconscious Bias',
        createdAt: new Date(),
        lastModified: new Date()
      },
      {
        code: 'CR-06',
        name: 'Women Lead - Sky is the limit',
        createdAt: new Date(),
        lastModified: new Date()
      }
    ]
    setData(data);
  }, []);

  const toggleSidebar = () => {
    let newSidebar = !sidebar;
    setSidebar(newSidebar);
  }

  return (
    <div className='d-flex' style={{ width: '100vw', height: '100vh' }}>
      <div className='sidebar bg-dark' style={{ width: sidebar ? '250px' : 0 }}>
        <div style={{ display: sidebar ? 'block' : 'none' }}>
          <div className='p-2'></div>
          <div className='text-center d-flex align-items-center'><img alt='' src={dishaLogo} style={{ width: 60 }} className='me-2' /><h3 className='text-white'>LinearLMS</h3></div>
          <div className='p-2'></div>
          <div className='text-center'><img alt='' src='https://picsum.photos/120/120' className='rounded-circle' /></div>
          <div className='p-2'></div>
          <div className='text-center'><h4 className='text-white'>Admin Console</h4></div>
          <div className='text-center text-white'><small>Hi Chandrakant!</small></div>
          <div className='p-2'></div>
          <div className='text-center d-flex flex-column'>
            <div className='text-white text-start'>
              <AdminNav selected='courses' />
            </div>
            <div className='p-4'></div>
            <div className='text-white'>Powered by <img alt='' src={sashaLogo} style={{ width: 40 }} /></div>
          </div>
        </div>
      </div>
      <div className='main-content d-flex flex-column' style={{ flex: 1, overflow: 'auto' }}>
        <Box>
          <AppBar position='static'>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => toggleSidebar()}
              ><Menu /></IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              </Typography>
              <IconButton onClick={() => navigate('/login')} color='inherit' ><ExitToApp /></IconButton>
            </Toolbar>
          </AppBar>
        </Box>
        <div className='content-area p-4 d-flex flex-column' style={{ flex: 1 }}>
          <div className='d-flex align-items-center justify-content-between'>
            <h3>Courses</h3>
            <div>
              <Fab color='primary' size='small'>
                <Add />
              </Fab>
            </div>
          </div>
          <div className='p-2'></div>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Code</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Last Modified</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.createdAt.toLocaleString()}</TableCell>
                    <TableCell>{row.lastModified.toLocaleString()}</TableCell>
                    <TableCell style={{ width: 120 }}>
                      <IconButton>
                        <Edit color='primary' />
                      </IconButton>
                      <IconButton>
                        <Delete color='error' />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className='p-2'></div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={6}
            rowsPerPage={10}
            page={0}
            onPageChange={() => { }}
            onRowsPerPageChange={() => { }}
          />
        </div>
      </div>
    </div>
  )
}

export default Courses