import { Button, Card, TextField } from '@mui/material'
import React, { useState } from 'react'
import './login.css'
import logo from '../../assets/images/logo.png'
import library from '../../assets/images/library.jpg'
import { useNavigate } from 'react-router-dom'
import dishaLogo from '../../assets/images/Dishaflex_logo.png'
import sashaLogo from '../../assets/images/sasha.png'

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ username: '', password: '' });

  const authenticate = () => {
    let path = `/home`;
    if (formData.username === 'admin') {
      navigate('/dashboard');
    }
    else {
      navigate(path);
    }
  }

  const handleUsernameChange = (e) => {
    let newFormData = { ...formData }
    newFormData.username = e.target.value;
    setFormData(newFormData);
  }

  const handlePasswordChange = (e) => {
    let newFormData = { ...formData }
    newFormData.password = e.target.value;
    setFormData(newFormData);
  }

  return (
    <div style={{ width: '100vw', height: '100vh', backgroundColor: '#A6D1E6', minWidth: '375px', overflow: 'auto' }} className='d-flex flex-column align-items-center justify-content-center'>
      <img src={dishaLogo} style={{ width: '100px' }} className='responsive-logo' />
      <div className='p-2 responsive-space'></div>
      <Card variant='outlined' className='d-flex shadow-sm' style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>
        <div className='side-section' style={{ flexBasis: 100, flexGrow: 1, width: 500, height: 550, backgroundImage: `url('${library}')`, backgroundSize: 'cover' }}>
          <div className='w-100 h-100 p-5' style={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}>
            <img src={dishaLogo} style={{ width: '100px' }} />
            <div className='p-3'></div>
            <h4 className='text-white'>Welcome to LinearLMS!</h4>
            <p className='text-white pt-4'>LinearLMS is enables your organization to streamline its e-learning process through course and content management, allocation and analysis.</p>
          </div>
        </div>
        <div style={{ flexBasis: 100, minWidth: '350px', backgroundColor: 'rgba(255, 255, 255, 0.7)' }} className='d-flex align-items-center p-3 form-section'>
          <div>
            <img src={logo} style={{ height: '50px' }} />
            <div className='p-2'></div>
            <p className='ps-2 pe-2'>Enter your email and password to log in</p>
            <div className='p-1'></div>
            <form className='w-100'>
              <TextField fullWidth variant='outlined' placeholder='Please enter your email address' label='Email Address' className='text-white' value={formData.username} onChange={handleUsernameChange} />
              <div className='p-2'></div>
              <TextField fullWidth type='password' variant='outlined' placeholder='Please enter your password' label='Password' value={formData.password} onChange={handlePasswordChange} />
              <div className='p-2'></div>
              <Button variant='contained' color='primary' onClick={authenticate}>Login</Button>
              <div className='p-2'></div>
              <a href='#'>Forgot Password</a>
            </form>
          </div>
        </div>
      </Card>
      <div className='p-2'></div>
      <div className='text-dark'>(C) 2022 DishaFlex, All Rights Reserved.</div>
      <div className='text-dark mt-2'><small>Powered by <img alt='sasha logo' className='ms-3' src={sashaLogo} style={{ maxWidth: '50px' }} /></small></div>
    </div>
  )
}

export default Login